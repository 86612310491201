import { methodService, apiService } from "./apiService";

const BASE_DONATION = '/upload-donations';

export const apiGetDetailUpload = async (id) => {
  const { data } = await apiService(
    BASE_DONATION + `/detail/${id}`,
    methodService.GET,
  );

  return data;
};

export const apiUploadCsv = async (dataUpload) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload`,
    methodService.POST,
    dataUpload,
  );

  return data;
};

export const apiUploadCreate = async (dataUpload) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload/create`,
    methodService.POST,
    dataUpload,
  );

  return data;
};

export const apiUploadToSalesforce = async (dataUpload) => {
  const { data } = await apiService(
    BASE_DONATION + `/uploadToSalesforce`,
    methodService.POST,
    dataUpload,
  );

  return data;
};

export const apiUploadList = async (dataUpload) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload`,
    methodService.GET,
    null,
    dataUpload,
  );
  return data;
};

export const apiDeleteUpload = async (id) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload/${id}`,
    methodService.DELETE,
  );
  return data;
};

export const apiDeleteSelectedUpload = async (dataDelete) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload`,
    methodService.DELETE,
    dataDelete,
  );
  return data;
};

export const apiDetailUpload = async (id) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload/${id}`,
    methodService.GET,
  );
  return data;
};

export const apiUpdateDonationUpload = async (dataUpload, id) => {
  const { data } = await apiService(
    BASE_DONATION + `/upload/${id}`,
    methodService.PUT,
    dataUpload,
  );

  return data;
};