// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form
} from "react-bootstrap";
import { IoMdCloudUpload, IoMdTrash } from "react-icons/io";
import { get } from "lodash";
import * as donationUploadRepo from "../../../../../../dataSource/donationUpload";
import { ImBin } from "react-icons/im";
import Swal from "sweetalert2";
import { useLocation, useHistory, useParams } from "react-router-dom";
import "./TableDonationList.css"
import MaterialReactTable from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material';

import { ModalFilter } from "../ModalFilter/ModalFilter";
import { ModalSort } from "../ModalSort/ModalSort";
import { ModalCreate } from "../ModalCreate/ModalCreate";

const customAmount = {
  benevity: "total_donation_to_be_acknowledged",
};


export const TableDonationList = (props) => {
  const [dataTable, setDataTable] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalSort, setShowModalSort] = useState(false);
  const [toggleChecked, setToggleChecked] = useState();
  const [modalIsHidden, setModalIsHidden] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [loading, setLoading] = useState(false)
  const [dataKeys, setDataKeys] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [loadingSalesforce, setLoadingSalesforce] = useState(false);
  
  //mui state
  const [columnFilter, setColumnFilter] = useState([]);
  const [globalFilter, setGlobalFilter] = useState([]);
  const tableInstanceRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [location]);

  useEffect(() => {
    if (dataTable.length > 0) setGlobalFilter([])
  }, [dataTable]);

  function sortColumns(object, type, cache) {
    const keys = Object.keys(object);
    let appliedPriority = [];
    if (cache && cache.length > 0) {
      appliedPriority = cache.filter(x => keys.includes(x));
    }

    if (appliedPriority.length > 0) {
      let newList = keys.filter((x) => !appliedPriority.includes(x));
      for (let i = appliedPriority.length - 1; i >= 0; i--) {
        newList.unshift(appliedPriority[i]);
      }
      return newList;
    } else {
      return keys;
    }
  }

  async function fetchData() {
    const file_type = get(location, "hash", " ").substr(1);
    setLoading(true)
    
    try {
      const { data } = await donationUploadRepo.apiUploadList({
        file_type,
        limit: 999,
      });

      let columns = [];
      const filteredData = data.filter(data => !data.salesforce_id)
      if (Array.isArray(data)) {
        filteredData.forEach((x) => {
          let keys = sortColumns(
            x,
            file_type,
            JSON.parse(localStorage.getItem(`${file_type}-column-list`))
          );
          setDataKeys(
            keys.filter((key) => !["_id", "__v", "file_type"].includes(key))
          );
          keys.forEach((key) => {
            if (
              columns.filter((x) => x.accessorKey === key).length < 1 &&
              !["_id", "__v", "file_type"].includes(key)
            ) {
              let columnName = key.split("_");
              let capital = columnName
                .map((x) => x.charAt(0).toUpperCase() + x.substr(1))
                .join(" ");
              columns.push({
                accessorKey: key,
                header: capital,
                size: 100,
                minSize: 100, 
                maxSize: 300,
              });
            }
          });
        });
      }

      if (filteredData.length > 0) {
        setColumns(columns);
        setDataTable(filteredData);
      } else {
        setDataTable([]);
        setColumns([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  async function deleteRow(id) {
    try {
      await donationUploadRepo.apiDeleteUpload(id);
      fetchData();
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    } catch (error) {
      console.log(error);
    }
  }

  async function saveToSalesforce(records = [], reset) {
    try {
      setLoadingSalesforce(true)
      const request = {
        data: records,
        file_type: get(location, 'hash', ' ').substr(1).split('_').join(' ')
      }
      const { response } = await donationUploadRepo.apiUploadToSalesforce(request)
      let errMsg = '';
      response.forEach(x => {
        if (!x.success) {
          errMsg += '<li class="response-error">Error:' + x.errors + '</li>'
        } else {
          errMsg += '<li class="response-success">Data Uploaded!</li>'
        }
      });
      if (response.filter(x => !x.success).length > 0) {
        Swal.fire({
          title: `oops there is some error from salesforce`,
          icon: 'error',
          html:
            'Here is the report: <br/>' +
            '<ul> ' +
            errMsg +
            '</ul>',
        })
      } else {
        Swal.fire(
          'Saved!',
          'All Data has been saved to salesforce.',
          'success'
        )
      }
    } catch (error) {
      const message = '<li class="response-error">Error:' + error.response?.data?.message + '</li>';
      Swal.fire({
        title: `oops there is error`,
        icon: 'error',
        html:
          'Here is the report: <br/>' +
          '<ul> ' +
          message +
          '</ul>',
      })
      console.log(error)
    } finally {
      fetchData()
      setLoadingSalesforce(false)
      reset()
    }
  }

  async function deleteSelected(records = [], reset) {
    try {
      setLoadingSalesforce(true)
      const request = {
        data: records,
      }
      const { response } = await donationUploadRepo.apiDeleteSelectedUpload(request)

    } catch (error) {
      console.log(error)
    } finally {
      reset()
      fetchData()
      setLoadingSalesforce(false)
    }
  }

  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <div>
      {columns.map((column, index) => (
        <div className="mb-3" key={index}>
          <Form.Check
            type="checkbox"
            defaultChecked={toggles ? "true" : "false"}
            label={column.text}
            key={column.dataField}
            onChange={() => onColumnToggle(column.dataField)}
          />
        </div>
      ))}
    </div>
  );

  const handleShowModalFilter = () => {
    setShowModalFilter(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalFilter = () => {
    setShowModalFilter(false);
    // setModalIsHidden(true);
  };

  const handleShowModalSort = () => {
    setShowModalSort(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalSort = () => {
    setShowModalSort(false);
    // setModalIsHidden(true);
  };

  const handleShowModalCreate = () => {
    setShowModalCreate(true);
    // setModalIsHidden(false);
  };

  const handleCloseModalCreate = () => {
    setShowModalCreate(false);
    // setModalIsHidden(true);
  };

  function totalAmount(data, param = "amount") {
    let amount = 0;
    if (data.length > 0) {
      amount = data
        .map((item) => item[param || "amount"])
        .reduce((prev, next) => {
          let firstVal = !isNaN(Number(prev)) ? Number(prev) : 0;
          let secondVal = !isNaN(Number(next)) ? Number(next) : 0;
          return firstVal + secondVal;
        });
    }
      return Number(amount).toFixed(2).replace(/\.00$/, '');
  }

  function mapFilltered(data){
    const rows = data.rows
    const mapRows = rows.map(x => x.original)
    return mapRows
  }

  function checkSelectedWhiteList(selected = {}){
    let whiteListIndex = Object.keys(selected).filter(x =>!dataTable[x].salesforce_id) 
    let whiteList = whiteListIndex.map(index => dataTable[index])
    return whiteList
  }

  return (
    <div className="container-donation-list">

      {dataTable.length > 0 && !loading ? <>
        <div className="container-action">
          <h1 className="mb-10">Donation</h1>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}>
          </div>
          <div
            style={{
              display: "flex",
              float:"right",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="success"
              style={{
                width: "130px",
                height: "35px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "right",
              }}
              onClick={handleShowModalCreate}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Helvetica Neue",
                    margin: "0",
                    lineHeight: "1.5",
                  }}
                >
                  Add Record
                    </span>
              </div>
            </Button>{" "}
                &nbsp;
                <Button
              style={{
                backgroundColor: "primary",
                width: "160px",
                height: "35px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "right",
              }}
              onClick={handleShowModalSort}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Helvetica Neue",
                    margin: "0",
                    lineHeight: "1.5",
                  }}
                >
                  Reorder Columns
                    </span>
              </div>
            </Button>
          </div></div>
        <div className="data-table-container">
          <ThemeProvider theme={createTheme({
            palette: {
              background: {
                default: "#fff",
              }
            }
          })}>
            <MaterialReactTable
              columns={columns}
              positionPagination='top'
              data={dataTable}
              initialState={{ density: 'compact', columnPinning: { right: ['mrt-row-actions'] } }}
              enableDensityToggle={true}
              enableRowSelection
              state={{columnFilters: columnFilter}}
              selectAllMode="all"
              renderTopToolbarCustomActions={(value) => (
                <div style={{
                  display:'flex',
                  flexDirection:'row',
                  visibility:
                    (get(location, "hash", " ").substr(1) === "" ||
                      !checkSelectedWhiteList(value.table.getState().rowSelection).length> 0) &&
                    "hidden",
                }}>
                  <Button
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#4cae4c",
                      color: "#4cae4c",
                      width: "205px",
                      height: "35px",
                      borderRadius: "4px",
                      display:'flex',
                      flexDirection:'row'
                    }}
                    disabled={loadingSalesforce}
                    onClick={() => 
                      saveToSalesforce(checkSelectedWhiteList(value.table.getState().rowSelection), value.table.resetRowSelection)
                    }
                  >
                    {loadingSalesforce && (
                      <span className="ml-1 spinner spinner-white"></span>
                    )}
                    <div>
                      <IoMdCloudUpload
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Helvetica Neue",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        Upload to Salesforce
                        </span>
                    </div>
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#fff",
                      color: "#b85c5c",
                      borderColor: "#ae4c4c",
                      width: "170px",
                      height: "35px",
                      borderRadius: "4px",
                      marginLeft: "5px",
                      display:'flex',
                      flexDirection:'row'
                    }}
                    disabled={loadingSalesforce}
                    onClick={() => Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      html:"<span class='response-warning'`>data that has been uploaded to salesforce will not be deleted<span/>",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteSelected(checkSelectedWhiteList(value.table.getState().rowSelection), value.table.resetRowSelection);
                      }
                    })}
                  >
                    {loadingSalesforce && (
                      <span className="ml-1 spinner spinner-white"></span>
                    )}
                    <div>
                      <IoMdTrash
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Helvetica Neue",
                          margin: "0",
                          lineHeight: "1.5",
                        }}
                      >
                        Delete Selected
                        </span>
                    </div>
                  </Button>
                  {/* {Object.keys(value || {}).length > 0 && console.log(value.table)} */}
                </div>
                
              )}
              positionToolbarAlertBanner="bottom"
              tableInstanceRef={tableInstanceRef}
              onColumnFiltersChange={(value) => {
                setGlobalFilter([])
                setColumnFilter(value)
              }}
              enableGlobalFilter={false}
              positionActionsColumn="last"
              enableRowActions
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Delete', //change header text
                  size: 60, //make actions column wider
                },
              }}
              renderRowActions={({row}) => (
                <div style={{ width: "25%" }}>
                  <Button
                    variant="danger"
                    style={{
                      width: "37px",
                      height: "30px",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                    }}
                    disabled={row.original.salesforce_id}
                    onClick={() =>
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteRow(row.original._id);
                        }
                      })
                    }
                  >
                    <ImBin />
                  </Button>
                </div>  
              )}
              muiTableBodyCellProps={({row, column}) => ({
                onClick: () => {
                  if(!column.id.startsWith('mrt-row')){
                    history.push({
                      pathname: `/donation-list/detail/${row.original._id}`,
                    })
                  }
                },
                sx: { cursor: 'pointer' },
              })}
            />
          </ThemeProvider>
        </div>
        <div className="total-record-container">
          {`Record count (Total) = ${dataTable.length
            } | Amount (Total) = $${totalAmount(dataTable, customAmount[get(location, "hash", " ").substr(1)])}`}{" "}
          <br />
          
          {tableInstanceRef.current !== null && `Record count (Filtered) =  ${mapFilltered(tableInstanceRef.current.getFilteredRowModel()).length > 0  ? 
            mapFilltered(tableInstanceRef.current.getFilteredRowModel()).length : dataTable.length
            } Amount (Filtered) = 
                  $${mapFilltered(tableInstanceRef.current.getFilteredRowModel()).length > 0
              ? totalAmount(mapFilltered(tableInstanceRef.current.getFilteredRowModel()), customAmount[get(location, "hash", " ").substr(1)])
              : totalAmount(dataTable, customAmount[get(location, "hash", " ").substr(1)])
            }`}
        </div>
      </>
        : <>no data</>
      }

      <ModalFilter
        onHide={handleCloseModalFilter}
        show={showModalFilter}
        CustomToggleList={CustomToggleList}
        columnToggleProps={props.columnToggleProps}
        modalIsHidden={modalIsHidden}
        setModalIsHidden={setModalIsHidden}
        setToggleChecked={setToggleChecked}
        toggleChecked={toggleChecked}
      />
      <ModalSort
        onHide={handleCloseModalSort}
        show={showModalSort}
        sortList={{ data: columns, dataSetter: setColumns }}
        file_type={get(location, "hash", " ").substr(1)}
        setDataKeys={(value) => {
          tableInstanceRef.current?.setColumnOrder(['mrt-row-select', ...columns, 'mrt-row-actions'])
        }}
      />
      <ModalCreate
        onHide={handleCloseModalCreate}
        show={showModalCreate}
        data={dataKeys}
        file_type={get(location, "hash", " ").substr(1)}
        afterSubmit={() => fetchData()}
      />
    </div >
  );
};
