// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Button,
  Alert,
  Row,
  Col,
  ButtonGroup,
  Collapse,
  Spinner,
} from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { get } from "lodash";
import * as donationUploadRepo from '../../../../../dataSource/donationUpload';

import { ModalUpdate } from "../DonationList/ModalUpdate/ModalUpdate";

import "./InfoDetail.css";

export const InfoDetail = (props) => {
  const [data, setData] = useState({});
  const [openSalesforce, setOpenSalesforce] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [salesforceLoading, setSalesforceLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [salesforceData, setSalesforceData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [pagination, setPagination] = useState({});
  const history = useHistory();
  const location = useLocation();
  let { uploadId } = useParams();

  useEffect(() => {
    getDetail(uploadId);
  }, [uploadId]);

  function categorizeSalesforce(data) {
    const categoryList = {
      main: [
        "Owner",
        "Donation_Date__c",
        "RecordType",
        "Donation_Status__c",
        "Name",
        "DonationSource__c",
        "Tax_Receipt_No__c",
        "Donation_Datetime__c",
        "X3rd_Ref_No__c",
        "Cleared_Datetime__c",
        "Donor_Type__c",
        "Account_Name__r",
        "Rejected_Date__c",
        "Contact_Name__r",
        "Reason_for_Cancellation__c",
        "Tax_Deductible__c",
        "Disbursement_Batch_No__c",
        "Electronic_Receipt_Sent__c",
        "Disbursement_Batch_Date__c",
        "Print_Receipt__c",
        "Discrepancy__c",
        "Receipt_Sent__c",
        "Organisation_Name_Entered__c",
      ],
      payment_details: [
        "Donation_Amount__c",
        "Programme_Event__r",
        "Payment_Method__c",
        "Foreign_Currency_Amount__c",
        "Bank__c",
        "Foreign_Currency_Type__c",
        "Cheque_No_Mode__c",
        "Donation_Type__c",
        "Auth_Code__c",
        "Donation_Category__c",
        "Deposit_to__c",
        "Import__c",
      ],
      recurring: [
        "Email_Entered__c",
        "Full_Name_Entered__c",
        "Address_Entered__c",
        "ID_Type__c",
      ],
      remarks: ["Remarks__c", "Remarks_Import__c"],
      system_details: ["CreatedBy", "LastModifiedBy"],
    };
    const result = {};

    Object.keys(categoryList).forEach((category) => {
      const obj = {};
      categoryList[category].forEach((key) => {
        obj[key] = data[key];
      });
      result[category] = obj;
    });
    return result;
  }

  async function getDetail(id) {
    try {
      setLoadingData(true);
      const
        { data, pagination }
          = await donationUploadRepo.apiDetailUpload(id);
      setPagination(pagination || {});
      setData(data);
      setLoadingData(false);
      const sfId = data.salesforce_id
      if (sfId) {
        setSalesforceLoading(true);
        const response = await donationUploadRepo.apiGetDetailUpload(
          sfId
        );
        const salesforceData = response.donation;
        const metaObj = {};
        salesforceData.metaData.forEach((element) => {
          metaObj[element.fullName] = element.label;
        });
        const data = salesforceData?.data;
        delete data.attributes;

        const categorized = categorizeSalesforce(data);

        setMetaData(metaObj);
        setSalesforceData(categorized);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingData(false);
      setSalesforceLoading(false);
    }
  }

  return (
    <div className="container-info-detail">
      <hr className="mb-7" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      ></div>
      <div className="container-content-detail">
        <ButtonGroup>
          {data.file_type && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#d4d4d4",
              }}
              onClick={() => {
                history.push(`/donation-list#${data.file_type}`);
              }}
            >
              <div>
                <span className="btn-cancel" style={{ fontWeight: "bold" }}>
                  Back
                </span>
              </div>
            </Button>
          )}
          {!data.salesforce_id && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#3d84b8",
              }}
              onClick={() => setModalShow(true)}
            >
              <div>
                <span className="btn-cancel" style={{ color: "white" }}>
                  Edit
                </span>
              </div>
            </Button>
          )}
        </ButtonGroup>
        <ButtonGroup style={{ float: "right" }}>
          {uploadId !== pagination.oldest && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#d4d4d4",
              }}
              onClick={() => {
                history.push(`/donation-list/detail/${pagination.oldest}`);
              }}
            >
              <div>
                <span className="btn-cancel" style={{ fontWeight: "bold" }}>
                  Oldest
                </span>
              </div>
            </Button>
          )}

          {uploadId !== pagination.latest && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#3d84b8",
              }}
              onClick={() => {
                history.push(`/donation-list/detail/${pagination.latest}`);
              }}
            >
              <div>
                <span className="btn-cancel" style={{ color: "white" }}>
                  Latest
                </span>
              </div>
            </Button>
          )}

          {pagination.previous !== undefined && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#f5bc42",
              }}
              onClick={() => {
                history.push(`/donation-list/detail/${pagination.previous}`);
              }}
            >
              <div>
                <span className="btn-cancel" style={{ color: "white" }}>
                  Previous
                </span>
              </div>
            </Button>
          )}

          {pagination.next !== undefined && (
            <Button
              className="btn-cancel-detail "
              variant="outline-secondary"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto",
                backgroundColor: "#28bd48",
              }}
              onClick={() => {
                history.push(`/donation-list/detail/${pagination.next}`);
              }}
            >
              <div>
                <span className="btn-cancel" style={{ color: "white" }}>
                  Next
                </span>
              </div>
            </Button>
          )}
        </ButtonGroup>
      </div>
      <div className="container-content-detail">
        <h1 className="title-detail">Donation Detail</h1>
        <hr />
        {loadingData && <Spinner animation="border" />}
        <Row>
          {data.salesforce_id && (
            <Col xl={6} xs={12}>
              <div>
                <Row>
                  <Col xl={6}>
                    <p className="content-detail">Salesforce Id</p>
                  </Col>
                  <Col xl={6}>
                    {" "}
                    <p style={{ margin: "0" }}>
                      <a
                        href={`https://d6f000002vgliuas--dmsdev1.sandbox.lightning.force.com/lightning/r/Donations__c/${data.salesforce_id}/view`}
                        target="_blank"
                        style={{
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        {data.salesforce_id}
                      </a>
                    </p>
                  </Col>
                </Row>
              </div>
              <hr />
            </Col>
          )}
          {Object.keys(data).map((key, index) =>
            ["_id", "__v", "file_type", "salesforce_id"].includes(key) ? (
              <></>
            ) : (
              <Col xl={6} xs={12}>
                <div>
                  <Row>
                    <Col xl={6}>
                      <p className="content-detail">
                        {key
                          .split("_")
                          .map((x) => x.charAt(0).toUpperCase() + x.substr(1))
                          .join(" ")}
                      </p>
                    </Col>
                    <Col xl={6}>
                      {" "}
                      <p style={{ margin: "0" }}>{data[key]}</p>
                    </Col>
                  </Row>
                </div>
                <hr />
              </Col>
            )
          )}
        </Row>
        {data.salesforce_id && (
          <>
            <h1 className="title-detail">
              Salesforce Detail
              <Button
                className="btn-cancel-detail"
                variant="outline-secondary"
                style={{
                  margin: "0px 10px",
                  // backgroundColor: "#d4d4d4",
                }}
                onClick={() => setOpenSalesforce((prev) => !prev)}
              >
                <div>
                  <span className="btn-cancel">
                    {openSalesforce ? "hide" : "show"}
                  </span>
                </div>
              </Button>
            </h1>
            <hr />
          </>
        )}
        <Collapse in={openSalesforce}>
          {data.salesforce_id && salesforceLoading ? (
            <Spinner animation="border" />
          ) : (
            <div>
              <Row>
                {salesforceData["main"] &&
                  Object.keys(salesforceData["main"]).map((key, index) =>
                    ["attributes", "Id"].includes(key) ? (
                      <></>
                    ) : (
                      <Col xl={6} xs={12}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {metaData[key] !== undefined
                                  ? metaData[key]
                                  : key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })
                                    .split("__r")[0]
                                    .split("_")
                                    .join(" ")}
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {salesforceData["main"][key] !== null &&
                                  typeof salesforceData["main"][key] === "object"
                                  ? salesforceData["main"][key].Name
                                  : get(salesforceData["main"], key, "")}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    )
                  )}
              </Row>
              <h2 className="title-detail">Payment Details</h2>
              <hr />
              <Row>
                {salesforceData["payment_details"] &&
                  Object.keys(salesforceData["payment_details"]).map(
                    (key, index) =>
                      ["attributes", "Id"].includes(key) ? (
                        <></>
                      ) : (
                        <Col xl={6} xs={12}>
                          <div>
                            <Row>
                              <Col xl={6}>
                                <p className="content-detail">
                                  {metaData[key] !== undefined
                                    ? metaData[key]
                                    : key
                                      .replace(/([A-Z])/g, " $1")
                                      .replace(/^./, function (str) {
                                        return str.toUpperCase();
                                      })
                                      .split("__r")[0]
                                      .split("_")
                                      .join(" ")}
                                </p>
                              </Col>
                              <Col xl={6}>
                                {" "}
                                <p style={{ margin: "0" }}>
                                  {salesforceData["payment_details"][key] !==
                                    null &&
                                    typeof salesforceData["payment_details"][
                                    key
                                    ] === "object"
                                    ? salesforceData["payment_details"][key]
                                      .Name
                                    : get(
                                      salesforceData["payment_details"],
                                      key,
                                      ""
                                    )}
                                </p>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                        </Col>
                      )
                  )}
              </Row>
              <h2 className="title-detail">Recurring</h2>
              <hr />
              <Row>
                {salesforceData["recurring"] &&
                  Object.keys(salesforceData["recurring"]).map((key, index) =>
                    ["attributes", "Id"].includes(key) ? (
                      <></>
                    ) : (
                      <Col xl={6} xs={12}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {metaData[key] !== undefined
                                  ? metaData[key]
                                  : key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })
                                    .split("__r")[0]
                                    .split("_")
                                    .join(" ")}
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {salesforceData["recurring"][key] !== null &&
                                  typeof salesforceData["recurring"][key] ===
                                  "object"
                                  ? salesforceData["recurring"][key].Name
                                  : get(salesforceData["recurring"], key, "")}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    )
                  )}
              </Row>
              <h2 className="title-detail">Remarks</h2>
              <hr />
              <Row>
                {salesforceData["remarks"] &&
                  Object.keys(salesforceData["remarks"]).map((key, index) =>
                    ["attributes", "Id"].includes(key) ? (
                      <></>
                    ) : (
                      <Col xl={6} xs={12}>
                        <div>
                          <Row>
                            <Col xl={6}>
                              <p className="content-detail">
                                {metaData[key] !== undefined
                                  ? metaData[key]
                                  : key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    })
                                    .split("__r")[0]
                                    .split("_")
                                    .join(" ")}
                              </p>
                            </Col>
                            <Col xl={6}>
                              {" "}
                              <p style={{ margin: "0" }}>
                                {salesforceData["remarks"][key] !== null &&
                                  typeof salesforceData["remarks"][key] ===
                                  "object"
                                  ? salesforceData["remarks"][key].Name
                                  : get(salesforceData["remarks"], key, "")}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <hr />
                      </Col>
                    )
                  )}
              </Row>
              <h2 className="title-detail">System Details</h2>
              <hr />
              <Row>
                {salesforceData["system_details"] &&
                  Object.keys(salesforceData["system_details"]).map(
                    (key, index) =>
                      ["attributes", "Id"].includes(key) ? (
                        <></>
                      ) : (
                        <Col xl={6} xs={12}>
                          <div>
                            <Row>
                              <Col xl={6}>
                                <p className="content-detail">
                                  {metaData[key] !== undefined
                                    ? metaData[key]
                                    : key
                                      .replace(/([A-Z])/g, " $1")
                                      .replace(/^./, function (str) {
                                        return str.toUpperCase();
                                      })
                                      .split("__r")[0]
                                      .split("_")
                                      .join(" ")}
                                </p>
                              </Col>
                              <Col xl={6}>
                                {" "}
                                <p style={{ margin: "0" }}>
                                  {salesforceData["system_details"][key] !==
                                    null &&
                                    typeof salesforceData["system_details"][
                                    key
                                    ] === "object"
                                    ? salesforceData["system_details"][key].Name
                                    : get(
                                      salesforceData["system_details"],
                                      key,
                                      ""
                                    )}
                                </p>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                        </Col>
                      )
                  )}
              </Row>
            </div>
          )}
        </Collapse>
        <ModalUpdate
          onHide={() => setModalShow(false)}
          show={modalShow}
          data={data}
          afterSubmit={() => getDetail(uploadId)}
        />
      </div>
    </div>
  );
};
