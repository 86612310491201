import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Error404} from '../src/App/Modules/Error/404';
import { UploadDonation } from './App/Modules/UploadDonation/pages/uploadDonation';
import { DonationListPage } from './App/Modules/UploadDonation/pages/donationList';
import { DonationDetailPage } from './App/Modules/UploadDonation/pages/donationDetail';
function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <UploadDonation />
          </Route>
          <Route path="/donation-list/detail/:uploadId">
            <DonationDetailPage />
          </Route>
          <Route path="/donation-list">
            <DonationListPage />
          </Route>
          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
