import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import {
  CloudUpload,
  Storage
} from '@material-ui/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import { channelMapping } from '../../helpers/mappingData';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: '#011627',
    color: '#ffff'
  },
  button: {
    color: '#ffff',
    marginRight: theme.spacing(2)
  },
  logo: {
    maxWidth: '75px',
    padding: '12px',
    marginRight: theme.spacing(2),
    cursor: 'pointer'
  }
}))

export const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = (data) => {
    setAnchorEl(null);
    
    if (channelMapping.includes(data)) {
      const label = data.replace(/\s+/g, '_').toLowerCase();
      history.push(`/donation-list#${label}`);
    }
  } 
  const handleHomePage = () => history.push('/');

  return (
    <div className={classes.grow}>
      <AppBar position='static' className={classes.appbar}>
        <Toolbar>
          <LazyLoadImage
              src={'https://www.lionsbefrienders.org.sg/wp-content/uploads/2021/01/LB-Logo-White-100x132.png'}
              className={classes.logo}
              onClick={handleHomePage}
            />
          <Button className={classes.button} startIcon={<CloudUpload />} onClick={handleHomePage}>Importation Page</Button>
          <Button className={classes.button} startIcon={<Storage />} onClick={handleClick}>Temporary Data</Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {channelMapping.map((el, index) => <MenuItem key={index} onClick={() => handleClose(el)}> Temporary Data - {el} </MenuItem>)}
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};
