// @ts-nocheck
import React, { useState } from "react";
import Swal from "sweetalert2";
import { Card, Form, Button } from "react-bootstrap";
import "./FormUpload.css";
import { channelMapping, delimiterMapping } from '../../helpers/mappingData';
import * as donationUploadRepo from '../../../../../dataSource/donationUpload';
import { useHistory } from 'react-router-dom';

export const FormUpload = (props) => {
  // const navigate = useNavigate();
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(null);;
  const [channel, setChannel] = useState(channelMapping[0]);
  const [loading, setLoading] = useState(false);
  const [delimiter, setDelimiter] = useState(delimiterMapping[0].icon);

  async function convertCSV() {
    const payload = new FormData();
    const label = channel.replace(/\s+/g, '_').toLowerCase();
    payload.append("file", uploadFile);
    payload.append("fileType", channel);
    payload.append("delimiter", delimiter);
    setLoading(true);
    try {
      const data = await donationUploadRepo.apiUploadCsv(payload);
      Swal.fire("Data Uploaded!", "", "success");
      setUploadFile(null);
      setChannel(channelMapping[0]);
      setDelimiter(",");
      setTimeout(() => {history.push(`/donation-list#${label}`)}, 2000)
      
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      Swal.fire(
        "Oops",
        `Error : ${errorMessage}, <br> make sure you enter the right delimiter and the first line is the column name.`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  }
  function uploadFileSubmit(file) {
    setUploadFile(file);
  }

  return (
    <div className="container-form-upload">
      <h3>Upload Data</h3>
      <hr />
      <Card style={{ borderColor: "1px solid #333" }}>
        <Card.Body style={{ padding: "24px" }}>
          <p>Choose File *)</p>
          <div style={{ marginBottom: "16px" }}>
            <Form.Control
              type="file"
              size="md"
              accept=".csv"
              onChange={(e) => uploadFileSubmit(e.target.files[0])}
            />
          </div>
          <p style={{ margin: "2px" }}>File Type</p>
          <Form.Group
            style={{ width: "50%", marginBottom: '14px' }}
            controlId="exampleForm.SelectCustomSizeSm"
          >
            <Form.Control
              as="select"
              size="md"
              // custom
              onChange={(e) => setChannel(e.target.value)}
            >
              {channelMapping.map((el, index) => <option key={index} value={el}>{el}</option>)}
            </Form.Control>
          </Form.Group>

          <p style={{ margin: "2px" }}>Csv File Delimiter</p>
          <Form.Group
            style={{ width: "50%" }}
            controlId="exampleForm.SelectCustomSizeSm"
          >
            <Form.Control
              as="select"
              size="md"
              // custom
              onChange={(e) => setDelimiter(e.target.value)}
            >
              {delimiterMapping.map((el, index) =>  <option key={index} value={el.icon}> {el.icon} ({el.label})</option>)}
            </Form.Control>
          </Form.Group>
          <div style={{ marginBottom: '14px' }}>
            {" "}
            <span className="info-upload">
              *) Only accept CSV(Comma Separated Value)
            </span>
          </div>
          <Button
            style={{
              backgroundColor: "#011627",
              borderColor: "#011627",
              width: "100px",
              marginTop: "15px",
              fontFamily: "Helvetica Neue",
            }}
            className="submit-upload"
            onClick={convertCSV}
          >
            {loading && <span className="ml-3 spinner spinner-white"></span>}
            Submit
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};
