export const channelMapping = [
  'Giving Sg', 
  'Give Asia', 
  'Simply Giving', 
  'Deeda',
  'Benevity', 
  'Oxpay'
];

export const delimiterMapping = [
  {
    label: 'Comma',
    icon: ','
  },
  {
    label: 'Semicolon',
    icon: ';'
  }
]