import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export const ModalFilter = (props) => {
  const {
    onHide,
    show,
    CustomToggleList,
    columnToggleProps,
    modalIsHidden,
    setModalIsHidden,
    setToggleChecked,
    toggleChecked,
  } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      autoFocus={false}
      enforceFocus={false}
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Table Filter Column</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomToggleList {...columnToggleProps} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
