// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";

export const ModalSort = (props) => {
  const {
    onHide,
    show,
    sortList,
    file_type,
    setDataKeys
  } = props
  const [columnList, setColumnList] = useState([])
  useEffect(() => {
    setColumnList(sortList.data)
  }, [sortList])

  function onSortEnd(value) {
    sortList.dataSetter(value);
    let cacheData = [];
    value.filter((x) => x.header !== "Delete").forEach((x) => {
      if (x.accessorKey) cacheData.push(x.accessorKey);
    });
    localStorage.setItem(`${file_type}-column-list`, JSON.stringify(cacheData));
    setDataKeys(cacheData);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      autoFocus={false}
      enforceFocus={false}
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reorder Table Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
          }}
        >
          <ReactSortable list={sortList.data} setList={onSortEnd}>
            {columnList.filter((x) => x.header !== "Delete").map((item, index) => (
              <li
                key={index}
                style={{
                  font: "200 20px/1.5 Helvetica, Verdana, sans-serif",
                  borderBottom: "1px solid #ccc",
                  zIndex: 99999999,
                  listStyleType: "none",
                  cursor: 'pointer'
                }}
              >
                {item.header}
              </li>
            ))}
          </ReactSortable>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
