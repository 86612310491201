import React from 'react';
import {Container, Typography, Box} from '@material-ui/core';
export const Error404 = () => {
  return (
    <>
      <Container maxWidth="xl">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <Typography variant="h3" fontWeight={'bold'}>
            404
          </Typography>
          <Box
            sx={{
              borderLeft: '3px solid #21252980',
              height: '90px',
              marginLeft: '20px',
              marginRight: '20px',
            }}></Box>
          <Typography variant="h5"> This page could not be found.</Typography>
        </div>
      </Container>
    </>
  );
};
