import React from "react";
import { Header } from "../../components/Navbar";
import { TableDonationList } from "../../components/DonationList/TableDonationList/TableDonationList";
import "bootstrap/dist/css/bootstrap.min.css";

export const DonationListPage = (props) => {
  return (
    <>
      <Header />
      <TableDonationList />
    </>
  );
};
